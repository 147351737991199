import { template as template_f5390856b2a64defba5ea2b70f97b297 } from "@ember/template-compiler";
const FKText = template_f5390856b2a64defba5ea2b70f97b297(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
