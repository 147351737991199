import { template as template_bf6873705e4d435388cce7618fbc6cb8 } from "@ember/template-compiler";
const FKLabel = template_bf6873705e4d435388cce7618fbc6cb8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
