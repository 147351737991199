import { template as template_d3bda15f92a148e9a62e8970f1305fe7 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import i18n from "discourse-common/helpers/i18n";
const UserSummarySection = template_d3bda15f92a148e9a62e8970f1305fe7(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
