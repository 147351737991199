import { template as template_8abe70f584404b2098cb4670d778e799 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8abe70f584404b2098cb4670d778e799(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
